<template>
  <v-container
    fluid
    class="pa-0"
    :style="marginTop"
  >
    <top-foreword entitle="LECTURERS" cntitle="嘉宾" />
    <div class="mx-auto my-0 py-0 px-3 px-sm-0" :style="maxWidth">
      <title-block title="主席团" />
      <title-caption />
      <lecturer-block :size="220" :items="chairmans" />
      <title-block title="会议委员" />
      <title-caption />
      <lecturer-block :items="committees" />
      <title-block :title="main_title" />
      <title-caption />
      <lecturer-block :items="mainLecturer" />
      <title-block :title="first_title" />
      <div class="d-flex align-center">
        <lecturer-leader :item="first_leader" />
        <title-caption :title="owner" />
      </div>
      <lecturer-block :items="firstLecturer" />
      <title-block :title="second_title" />
      <div class="d-flex align-center">
        <lecturer-leader :item="second_leader" />
        <title-caption :title="owner" />
      </div>
      <lecturer-block :items="secondLecturer" />
      <title-block :title="third_title" />
      <div class="d-flex align-center">
        <lecturer-leader :item="third_leader" />
        <title-caption :title="owner" />
      </div>
      <lecturer-block :items="thirdLecturer" />
      <title-block :title="fourth_title" />
      <div class="d-flex align-center">
        <lecturer-leader :item="fourth_leader" />
        <title-caption :title="owner" />
      </div>
      <lecturer-block :items="fourthLecturer" />
      <title-block :title="fifth_title" />
      <div class="d-flex align-center">
        <lecturer-leader :item="fifth_leader" />
        <title-caption :title="owner" />
      </div>
      <lecturer-block :items="fifthLecturer" />
    </div>
    <bottom-banner />
  </v-container>
</template>
<script>
import TopForeword from '@/views/components/TopForeword.vue'
import TitleBlock from '@/views/components/TitleBlock'
import TitleCaption from '@/views/components/TitleCaption'
import LecturerBlock from '@/views/components/LecturerBlock'
import LecturerLeader from '@/views/components/LecturerLeader'
import BottomBanner from '@/views/components/BottomBanner'
import Tools from '@/mixins/Tools.js'
import Datas from '@/mixins/Datas.js'
export default {
  name: 'Lecturer',
  mixins: [Tools, Datas],
  components: {
    TopForeword,
    TitleBlock,
    TitleCaption,
    LecturerBlock,
    LecturerLeader,
    BottomBanner
  },
  data() {
    return {
      owner: '（出品人，会议嘉宾按姓氏首字母排序）'
    }
  }
}
</script>
<style lang="sass" scoped>
.cs-alter-bg
  background-image: linear-gradient(to right, #016ACB 0%, #01BDCB 100%)
  box-shadow: inset 0 -2px 3px 0 rgba(255,255,255,0.50)
</style>
